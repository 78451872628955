.products {
    /* background-color: rgb(242, 239, 232) */
    background-color: #000000;
    align-items: center;
    justify-content: center;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    max-width: 100%;
    text-align: center;
    /* overflow: hidden; */
}

.header h1 {
    font-family: 'Times New Roman';
    font-size: 3.2rem;
    align-items: center;
    color: rgb(255, 255, 255);
    margin: 10px;
    padding: 10px;
    padding-top : 15px;
}

.products h2 {
    font-family: 'Times New Roman';
    text-align: center;
    margin: 10px;
    padding: 10px;
    margin-left: 0;
    font-weight: bold;
    font-size: 1.9em;
    height: auto;
    color: rgb(255, 255, 255);
}

.property-head{
    margin: 10px;
    padding: 10px;
}

.products p {
    color: rgb(255, 255, 255);
}

.productrow {
    flex: wrap;
    background-color: none;
    margin: 25px;
    /* padding: 10px; */
    width: 100%;
    /* height: 900px; */
    height: auto;
    position: relative;
    transition: filter 0.3s ease-in-out;
    animation: change 3s infinite ease-in-out;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.para {
    width: 78%;
    height: auto;
    font-size: bold;
    color: #666;
    font-size: 1.3em;
    margin: auto;
    padding: 30px;
    font-family: 'Times New Roman';
    text-align: left;
    justify-content: center;
    /* border :1px solid black; */
    align-items: center;
    justify-content: center;
}

.details {
    width: 400px;
    height: auto;
    background: rgba(0, 0, 0, 0.5);
    color: #ffffff;
    opacity: 0;
    transition: opacity 0.3s ease;
    text-align: center;
}

.products h2:hover {
    cursor: pointer;
    color: rgb(104, 74, 29);
}

.product-box img {
    transition: transform 0.3s ease-in-out;
    justify-content: space-between;
    align-items: center;
    justify-content: center;
}


.img-wrap {
    display: flex;
    justify-content: center;
    position: relative;
    width: 100%;
    height: auto;
    /* gap: 20px; */
}

.hover-text {
    position: relative;
    top: 0;
    left: 50;
    transform: translate(-50%, -50%);
    opacity: 0;
    transition: opacity 0.3s ease;
}

.product-box {
    width: 410px;
    height: auto;
    border: 1px solid black;
    background-color: rgb(0, 0, 0);
    margin: 20px;
    padding: 10px;
    border-radius: 9px;
    overflow: hidden;
}

.product-title {
    font-size: 18px;
    margin-bottom: 8px;
    font-family: 'Times New Roman';
    border: 1px solid rgb(255, 255, 255);
    color: rgb(255, 255, 255);
    background-color: #000000;
    padding: 12px;
    margin: auto;
    transition-duration: 0.4s;
    display: block;
    text-transform: uppercase;
    font-weight: bold;
    font-size: 0.9em;
    width: 95%;
    border-radius: 10px;
}

.product-title:hover {
    cursor: pointer;
    background-color: rgb(172, 172, 172);
}

.img-container {
    width: 100%;
    background-color: #000000;
    height: auto;
    margin-top: 0;
    margin-left: 0;
    margin-right: 0;
    margin-bottom: 10px;
    border: 1px solid rgb(85, 85, 85);
    border-radius: 10px;
}

.img-container img {
    width: 100%;
    background-color: #666;
    border-radius: 10px;
}

.product-box img:hover {
    transform: scale(1.1);
}


.addcard-btn {
    border: 1px solid black;
    color: rgb(0, 0, 0);
    padding: 12px;
    margin: auto;
    transition-duration: 0.4s;
    display: block;
    text-transform: uppercase;
    font-weight: bold;
    font-size: 0.9em;
    width: 95%;
    border-radius: 10px;
}

.addcard-btn:hover {
    cursor: pointer;
    background-color: rgb(52, 52, 52);
}

.overlay {
    color: rgb(255, 255, 255);
    width: 410px;
    height: 400px;
    top: 0;
    left: 0;
    position: absolute;
    background: rgba(0, 0, 0, 0.6);
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    opacity: 0;
    transition: 0.6s;
    margin-left: 65px;
    margin-top: 20px;
    border-radius: 10px;
}

.overlay p {
    text-align: left;
    padding: 15px;
}

.overlay2 p {
    text-align: left;
    padding: 15px;
}

.overlay3 p {
    text-align: left;
    padding: 15px;
}


.overlay2 {
    color: white;
    width: 386px;
    height: 390px;
    top: 0;
    margin-top: 30px;
    position: absolute;
    background: rgba(0, 0, 0, 0.6);
    display: flex;
    text-align: center;
    justify-content: center;
    flex-direction: column;
    border-radius: 10px;
    opacity: 0;
    transition: 0.6s;
}

.overlay3 {
    color: white;
    width: 410px;
    height: 400px;
    top: 0;
    right: 0;
    margin-top: 20px;
    margin-right: 65px;
    position: absolute;
    background: rgba(0, 0, 0, 0.6);
    display: flex;
    text-align: center;
    justify-content: center;
    flex-direction: column;
    border-radius: 10px;
    opacity: 0;
    transition: 0.6s;
}

.overlay p {
    font-size: medium;
}

img-contant {
    position: absolute;
}

.overlay:hover {
    opacity: 1;
}

.overlay2:hover {
    opacity: 1;
}

.overlay3:hover {
    opacity: 1;
}

@media screen and (max-width: 600px) {
    .img-container {
        gap: 5px;
    }

    .img-wrap {
        display: flex;
        flex-direction: column;
        width: auto;
        height: auto;
    }

    .overlay {
        display: none;
    }

    .overlay2 {
        display: none;
    }

    .overlay3 {
        display: none;
    }
}