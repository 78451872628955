.homepg {
    height: 550px;
    width: 100%;
    display: flex;
    background-color: #000000;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.head {
    width: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.animate-logo {
    display: flex;
    align-items: center;
    justify-content: center; 
    margin: auto;
    width: 300px;
    height: 400px;
}

.head h1 {
    font-size: 130px;
    font-family: 'Times New Roman', Times, serif;
    color: rgb(255, 255, 255);
    display: inline;
    margin: 0;
}

.head h3 {
    font-family: 'Times New Roman';
    color: rgb(255, 255, 255);
}

@media screen and (max-width: 600px) {
    .homepg {
        width: 100%;
        height: auto;
        margin-top: -75px;
    }

    .head h1{
        font-size: 70px;
    }
}