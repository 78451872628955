.App {
  text-align: center;
  background-color: #000000;
  width: 100%;
  height: auto;
  margin: 0;
  padding: O;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}


@media screen and (max-width: 500px) {
  .App{
    width: 100%;
    max-width: none;
    height: auto;
  }
}
