.contactbox{
    height: auto;
    width: 100%;
    background-color: none;
    box-shadow: 0 2px 5px rgba(255, 255, 255, 0.1);
    text-align: center;
    display: flex;
    
}

.contactbox p{
    text-align: center;
}

.contactus h1{
    font-family: 'Times New Roman';
    font-size: 30px;
}

.contactbox {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    transition: fliter 0.3s ease-in-out;
}

.contactus {
    width: 100%;
    height: auto;
    margin: 25px;
    padding: 10px;
    font-family: 'Times New Roman';
    color: rgb(104, 74, 29);
    border-radius: 10px;
}

.contact-coloum{
    display: flex;
    flex-direction: column;
    float: left;
    margin-left: 60px;
    padding:20px ;
}

.contactus h3{
    font-size: bold;
}

.contactus h2{
    color: rgb(255, 255, 255);
}

.contactus:hover{
    cursor: pointer;
}

.contactus h2{
    border: bottom 1px solid rgb(255, 255, 255) ;
    margin: 5px;
}

.contactus p{
    text-align: left;
    width: 100%;
    margin-left: 240px;
}

.follow-us{
    margin-right: 10px;
    width: 100%;
}

.follow-us h2{
    border: bottom 1px solid rgb(255, 255, 255) ;
    margin: 5px;
    color: white;
    font-family: 'Times New Roman', Times, serif;
}

.line{
    border-bottom: 0.5px solid rgb(191, 191, 191);
    width: 100%;
    padding: 2px;
}

.icon{
    display: inline-block;
    align-items: center;
    justify-content: center;
    padding: 26px;
    margin-right: 10px; 
    color: rgb(255, 255, 255);
}

.icon:hover{
    color: rgb(104, 74, 29);
    cursor: pointer;
}


.QNA h2{
    border: bottom 1px solid rgb(131, 131, 131) ;
    margin: 5px;
    font-family: 'Times New Roman', Times, serif;    
    font-size: bold;
}

.indexlogo {
    width: 100%;
    height: auto;
    display: flex;
    float: left;
    background-color: none;
}

.brandlogo{
    height: auto;
    padding: 5px;
    justify-content: center;
    margin: auto;
    transition: height 0.3s ease;
}

.brandlogo:hover {
    scale: (1.1);
}

.brandlogo img{
    width: 150px;
    justify-content: center;
    transition: height 0.3s ease;
}

.brandlogo img:hover{
    scale: (1.1);

}

.brand{
    font-family: 'Times New Roman';
    font-size : 120%;
    align-items: center;
    justify-content: center;
    color: rgb(255, 255, 255);
}

.follo-logo{
    width: 100%;
    display: flex;
}

.left-contant{
    text-align: left;
    display: flex;
    float: left;
    padding: 10px;
    color: white;
}
.right-contant{
    text-align: left;
    display: flex;
    float: right;
    padding: 10px;
    color: white;
}

@media  screen and (max-width: 600px) {
        .follow-us{
            display: flex;
            flex-direction: column;
        }
        .follow-us .icon{
            margin: auto;
        }
        .left-contant{
            text-align: left;
            display: flex;
            float: left;
        }
        .right-contant{
            text-align: left;
            display: flex;
            float: right;
        }
}