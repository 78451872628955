.slider{
    width: 100%;
    height: 600px;
    background-color: none;
    overflow: hidden;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.img-slider{
    width: 100%;
    height: 600px;
    align-items: center;
    justify-content: center;
}

.img-slider .slide img{
    width: 90%;
    height: 600px;
    border-radius: 10px;
    display: block;
    margin: auto;
    z-index: -1;
}

.info {
    font-family: 'Times New Roman';
    z-index: 999;
    color: rgb(154, 105, 105);
}

.slide-btn{
    position: absolute;
    color: rgb(118, 42, 42);
    width: 15px;
    height: 10px;
    border-radius: 10px;
    cursor: pointer;
    margin-left: 0;
    z-index: 999;
    transform: translate(-50% ,-50%);
}

.slide-btn:hover{
    background-color: rgb(101, 105, 105);
}

@media screen and (max-width: 600px){
    .slider{
        height: auto;
        width: auto ;
    }
    .img-slider{
        width: auto;
        height: auto;
    }

}