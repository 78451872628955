.Coridoor-style{
    margin: 0;
    padding: 0;
    background-color: black;
}

.Coridoor-logo{
    top: 0;
    left: 0;
    width: 90px;
    height: 80px;
}

.Coridoor-nav{
    justify-content: center;
    color: white;
    background-color: gray;
    height: 90px;
    width: 100%;
    border-radius: 10px;
}

.C-nav{
    margin: auto;
}

.C-nav a{
    font-family: 'Times New Roman', Times, serif;
    font-size: bold;
}

.C-nav a{
    float: left;
    margin: 20px;
    justify-content: center;
    align-items: center;
}
.Coridoor-nav li{
    margin-right: 10px;
    font-style: none;
    text-decoration: none;
    color: white;
}

.property-details{
    width: 100%;
    height: auto;
    display: flex;
    margin: 10px;
    justify-content: center;

}

.prop-details{
    width: 350px;
    height: auto;
    margin: 20px;
    border: 1px solid white;
    border-radius: 10px;
}

.prop-details img{
    border-radius: 10px;
}