.small-slider{
    height: auto;
    width: 100%;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.3);
}

.small-container{
    margin-top : 150px;
    display: flex;
    float: left;
    width: 100%;
    background-color: rgb(0, 0, 0);
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.3);
}

.small-img{
    position: relative;
    display: inline-block;
    width: 700px;
    height: auto;
    margin: auto;
    margin-right: 20px;
    /* border-radius: 10px; */
    padding: 15px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.3);
    overflow: hidden;
    transition: transform 0.3s ease;
}

.small-img img{
    border-radius: 10px;
    position: relative;
}

.small-img:hover {
    /* filter: brightness(0.6); */
    transform: scale(1.1);
}

.overlay-cat{
    display: flex;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    transition: opacity 0.3s ease;
    justify-content: center;
    color: white;
    align-items: center;
    font-family: 'Times New Roman';
    font-size: 220%;
    background-image: transparent;
    z-index: 999;
    transition: 0.6s;
}

.overlay-cat:hover{
    opacity: 1;
}

@media only screen and (max-width: 768px){
    .small-container{
        /* margin-top: 200px; */
        display: none;
    }


}